<template>
  <LoadingBar v-if="loading"></LoadingBar>
  <Page v-else :title="pageTitle">
    <v-form>
      <v-row>
        <v-col cols="8">
          <v-text-field v-model="search" label="Search"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-radio-group v-model="show">
            <v-row>
              <v-col>
                <v-radio label="Show Active" value="active"></v-radio>
              </v-col>
              <v-col>
                <v-radio label="Show All" value="all"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :headers="this.headers"
      :items="this.shownSources"
      :search="search"
      item-key="id"
      no-data-text="You have no feed sources."
      no-results-text="No matching feed sources"
      :items-per-page="15"
      :footer-props="{
        itemsPerPageText: 'Rows count',
        pageText: 'Navigate Pages',
        itemsPerPageAllText: 'All'
      }"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="editItem(item)" v-bind="attrs" v-on="on"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Edit Feed Source</span>
        </v-tooltip>
        <span v-if="item.is_active">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-cancel</v-icon
              >
            </template>
            <span>Deactivate Feed Source</span>
          </v-tooltip>
        </span>
        <span v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-check</v-icon
              >
            </template>
            <span>Activate Feed Source</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-icon v-if="item.is_active" color="green">mdi-check</v-icon>
        {{ item.is_active ? "Active" : "Inactive" }}
      </template>
    </v-data-table>
    <v-row class="pa-5">
      <v-btn color="secondary" @click="backToFarm()">Return to Farms</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addItem()">Add Feed Source</v-btn>
    </v-row>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import LoadingBar from "@/components/loaders/LoadingBar";
import { mapActions, mapState } from "vuex";
export default {
  name: "SourcesBrowse",
  components: { LoadingBar, Page },
  data() {
    return {
      loading: true,
      search: "",
      show: "active",
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "title" },
        { text: "Number", value: "number" },
        { text: "Feed Type", value: "feed_type.title" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState("sources", { sources: "items" }),
    ...mapState("farms", { farm: "detailItem" }),
    shownSources() {
      if (this.loading) return [];
      return this.show === "active"
        ? this.sources.filter(source => source.is_active)
        : this.sources;
    },
    farmId() {
      return this.$route.params.id;
    },
    pageTitle() {
      let title = "Browse Feed Sources";
      if (this.farm && this.farm.title) {
        title += ` for "${this.farm.title}"`;
      }
      return title;
    }
  },
  methods: {
    ...mapActions("sources", ["getFarmSources", "editFarmSource"]),
    ...mapActions("farms", ["getFarm"]),
    toggleActivation(item) {
      this.editFarmSource({
        id: item.id,
        farm_id: item.farm_id,
        is_active: !item.is_active
      });
    },
    editItem(item) {
      this.$router.push(`/farms/${item.farm_id}/sources/edit/${item.id}`);
    },
    addItem() {
      this.$router.push(`/farms/${this.farmId}/sources/add`);
    },
    backToFarm() {
      this.$router.push("/farms/browse");
    },
    loadFarm(id) {
      return new Promise((resolve, reject) => {
        if (id === this.farm.id) {
          resolve(true);
        } else
          this.getFarm(id)
            .then(response => resolve(response))
            .catch(error => reject(error));
      });
    }
  },
  mounted() {
    this.loading = true;
    Promise.all([this.loadFarm(this.farmId), this.getFarmSources(this.farmId)])
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        console.warn(error.message);
        this.backToFarm();
      });
  }
};
</script>

<style scoped></style>
